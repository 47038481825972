import React from "react";
import {
  VStack,
  Text,
  Button,
  Card,
  CardBody,
  SimpleGrid,
  HStack,
  Spacer,
  Divider,
  Tooltip,
  Icon,
  Center,
} from "@chakra-ui/react";
import PagedFlowLayoutFormSection from "../../student-details/components/PagedFlowLayoutFormSection";
import PagedFlow from "../../student-details/components/PagedFlow";
import PagedFlowLayout from "../../student-details/components/PagedFlowLayout";
import PagedFlowLayoutHeader from "../../student-details/components/PagedFlowLayoutHeader";
import { ClassTimeSlot } from "./CourseSelectionPage";
import { convertToReadableDate } from "../../student-details/utils/date";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { FaRegClock, FaRegBuilding, FaRegCalendarAlt } from "react-icons/fa";

interface SelectedClassesSectionProps {
  selectedClasses: ClassTimeSlot[];
  onRemoveClass: (classCode: string) => void;
  onContinueToPayment: () => Promise<void>;
}

const SelectedClassesSection: React.FC<SelectedClassesSectionProps> = ({
  selectedClasses,
  onRemoveClass,
  onContinueToPayment,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <PagedFlowLayout>
      <PagedFlowLayoutHeader title="Selected course(s)" />
      <Divider mt={4} />
      <PagedFlowLayoutFormSection mt={10}>
        {selectedClasses.length === 0 && (
          <Center>
            <Text as="i" color="gray.500" mt={6}>
              No courses selected yet, please select a level and course above to
              view available options.
            </Text>
          </Center>
        )}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} w="full">
          {selectedClasses.map((cls) => (
            <Card
              key={cls.classCode}
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              borderRadius="20px"
            >
              <CardBody p={6}>
                <VStack align="stretch" spacing={2}>
                  <HStack width="full" alignItems="flex-start">
                    <VStack alignItems="flex-start" spacing={0}>
                      <Text fontWeight={600} fontSize="18px">
                        {cls.courseName}
                      </Text>
                      <Text fontSize="16px" color="#4A4A4A">
                        {cls.levelTitle}
                      </Text>
                    </VStack>
                    <Spacer />
                    <Tooltip label="Remove" hasArrow>
                      <Button
                        size="sm"
                        variant="ghost"
                        colorScheme="grey"
                        onClick={() => onRemoveClass(cls.classCode)}
                      >
                        <CloseIcon boxSize={3} />
                      </Button>
                    </Tooltip>
                  </HStack>
                  <VStack spacing={2} alignItems="flex-start">
                    <HStack spacing={2} mt={1}>
                      <Icon
                        as={FaRegCalendarAlt}
                        color="#61AFAA"
                        fontSize={20}
                        stroke="currentColor"
                      />
                      <Text color="#4A4A4A">
                        {{
                          Mon: "Monday",
                          Tue: "Tuesday",
                          Wed: "Wednesday",
                          Thu: "Thursday",
                          Fri: "Friday",
                          Sat: "Saturday",
                          Sun: "Sunday",
                        }[cls.day] || cls.day}
                      </Text>
                    </HStack>
                    <HStack spacing={2}>
                      <Icon
                        as={FaRegClock}
                        color="#61AFAA"
                        fontSize={20}
                        stroke="currentColor"
                      />
                      <Text color="#4A4A4A">{`${cls.startTime} - ${cls.endTime}`}</Text>
                    </HStack>
                    <HStack spacing={2}>
                      <Icon
                        as={FaRegBuilding}
                        color="#61AFAA"
                        fontSize={20}
                        stroke="currentColor"
                      />
                      <Text color="#4A4A4A">{cls.centreName}</Text>
                    </HStack>
                  </VStack>

                  <Text color="#4A4A4A" fontStyle="italic" mt={2}>
                    Starting on {convertToReadableDate(cls.selectedStartDate)}
                  </Text>
                </VStack>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
        <HStack justifyContent="flex-end">
          <Button
            variant="ms-solid"
            colorScheme="blue"
            // size="lg"
            width="fit-content"
            mt={16}
            onClick={async () => {
              setIsLoading(true);
              try {
                await onContinueToPayment();
              } finally {
                setIsLoading(false);
              }
            }}
            isDisabled={selectedClasses.length === 0}
            rightIcon={<ArrowForwardIcon />}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </HStack>
      </PagedFlowLayoutFormSection>
    </PagedFlowLayout>
  );
};

export default SelectedClassesSection;
