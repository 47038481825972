import { Box, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayout({
  title,
  subtitle, // label displayed above the title
  description, // explanatory text below the title
}: {
  title: string;
  subtitle?: string;
  description?: string;
}) {
  return (
    <VStack alignItems="flex-start" gap={2}>
      {subtitle && (
        <Text
          fontSize="20px"
          fontWeight={500}
          lineHeight="100%"
          color="#013768"
        >
          {subtitle}
        </Text>
      )}
      <Text fontSize="28px" fontWeight={700} lineHeight="100%">
        {title}
      </Text>
      {description && (
        <Text fontSize="18px" fontWeight={400} lineHeight="140%">
          {description}
        </Text>
      )}
    </VStack>
  );
}

export default PagedFlowLayout;
