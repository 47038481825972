import React, { useState } from "react";
import {
  VStack,
  Text,
  Box,
  SimpleGrid,
  Heading,
  HStack,
  Badge,
  Select,
  Button,
  Divider,
  Grid,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import {
  ClassAvailability,
  ClassTimeSlot,
  CourseOptions,
} from "./CourseSelectionPage";
import { convertToReadableDate } from "../../student-details/utils/date";
import CenterFilterComboBox from "./CenterFilterComboBox";
import DayFilterComboBox from "./DayFilterComboBox";

interface CentreClasses {
  centreName: string;
  address: string;
  timeSlots: ClassAvailability[];
}

interface ClassAvailabilitySectionProps {
  selectedClasses: ClassTimeSlot[];
  classAvailability: ClassAvailability[];
  classAvailabilityLevel: string;
  classAvailabilityCourse: string;
  classAvailabilityFilterOptions: CourseOptions | null;
  handleAddClass: (classInfo: ClassTimeSlot) => void;
}

const ClassAvailabilitySection: React.FC<ClassAvailabilitySectionProps> = ({
  selectedClasses,
  classAvailability,
  classAvailabilityLevel,
  classAvailabilityCourse,
  classAvailabilityFilterOptions,
  handleAddClass,
}) => {
  const [selectedClass, setSelectedClass] = useState<ClassAvailability | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [filterByCenters, setFilterByCenters] = useState<string[]>([]);
  const [filterByDays, setFilterByDays] = useState<string[]>([]);

  // if (!classAvailability || classAvailability.length === 0) {
  //   return null;
  // }

  // Filter classes by centers and days first
  const filteredClassAvailability = classAvailability.filter((cls) => {
    // If no filters are set, show everything
    if (filterByCenters.length === 0 && filterByDays.length === 0) return true;

    // Check center filter
    const passesCenterFilter =
      filterByCenters.length === 0 || filterByCenters.includes(cls.centreName);

    // Check day filter
    const slotDays = cls.day.split(",").map((day) => {
      const dayMap = {
        Mon: "Monday",
        Tue: "Tuesday",
        Wed: "Wednesday",
        Thu: "Thursday",
        Fri: "Friday",
        Sat: "Saturday",
        Sun: "Sunday",
      };
      return dayMap[day.trim() as keyof typeof dayMap] || day.trim();
    });
    const passesDayFilter =
      filterByDays.length === 0 ||
      slotDays.some((day) => filterByDays.includes(day));

    // Must pass both filters
    return passesCenterFilter && passesDayFilter;
  });

  // Group filtered classes by centre
  const centreClassesMap = filteredClassAvailability.reduce((acc, cls) => {
    if (!acc[cls.centreName]) {
      acc[cls.centreName] = {
        centreName: cls.centreName,
        address: cls.address,
        timeSlots: [],
      };
    }
    acc[cls.centreName].timeSlots.push({
      classCode: cls.classCode,
      centreName: cls.centreName,
      address: cls.address,
      levelTitle: cls.levelTitle,
      courseCategory: cls.courseCategory,
      courseName: cls.courseName,
      day: cls.day,
      startTime: cls.startTime,
      endTime: cls.endTime,
      status: cls.status,
      startDate: cls.startDate,
      availability: cls.availability,
      upcomingSessions: cls.upcomingSessions,
    });
    return acc;
  }, {} as Record<string, CentreClasses>);

  // Filter out null centreNames and sort alphabetically by centreName
  const centreClasses = Object.values(centreClassesMap)
    .filter((centre) => centre.centreName != null)
    .sort((a, b) => a.centreName.localeCompare(b.centreName));

  // Day order map for correct sorting
  const dayOrderMap: Record<string, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // Sort timeslots for each centre by day and startTime
  centreClasses.forEach((centre) => {
    centre.timeSlots.sort((a, b) => {
      const dayOrderA = dayOrderMap[a.day] || 0;
      const dayOrderB = dayOrderMap[b.day] || 0;

      // If days are different, return day comparison
      if (dayOrderA !== dayOrderB) return dayOrderA - dayOrderB;

      // If days are same, sort by startTime
      return a.startTime.localeCompare(b.startTime);
    });
  });

  const handleSelectClass = (classInfo: ClassAvailability) => {
    if (selectedClass && selectedClass.classCode === classInfo.classCode) {
      setSelectedClass(null);
    } else {
      setSelectedClass(classInfo);
    }

    // Date should always be reset when a class is selected
    setSelectedDate(null);
  };

  return (
    // <PagedFlowLayout>
    //   <PagedFlowLayoutHeader title="Choose a class" />
    <Box>
      <Divider />
      <VStack alignItems="flex-start" spacing={0} mt={6}>
        <HStack spacing={3}>
          <Box
            backgroundColor="#E7EDFF"
            color="#6D8CE9"
            fontSize="16px"
            fontWeight={700}
            py={2}
            px={4}
            borderRadius="10px"
          >
            {classAvailabilityLevel}
          </Box>
          <Box
            backgroundColor="#E7EDFF"
            color="#6D8CE9"
            fontWeight={700}
            py={2}
            px={4}
            borderRadius="10px"
          >
            {classAvailabilityCourse}
          </Box>
        </HStack>
        <VStack mt={8} alignItems="flex-start" spacing={0}>
          <Text fontWeight={500} fontSize="16px" color="4A4A4A">
            Filters{" "}
          </Text>
          <Grid
            templateColumns="auto 1fr"
            rowGap={4}
            columnGap={{ base: 6, md: 8 }}
            width="100%"
            alignItems="center"
            mt={4}
          >
            <Text fontWeight={500} fontSize="16px" whiteSpace="nowrap">
              Location
            </Text>
            <CenterFilterComboBox
              selectedCenters={filterByCenters}
              onCenterChange={(centers) => setFilterByCenters(centers)}
              centerOptions={
                classAvailabilityFilterOptions?.zoneToCentreNames ?? {}
              }
            />
            <Text fontWeight={500} fontSize="16px" whiteSpace="nowrap">
              Day
            </Text>
            <DayFilterComboBox
              selectedDays={filterByDays}
              onDayChange={(days) => setFilterByDays(days)}
            />
          </Grid>
        </VStack>
      </VStack>
      <Divider mt={6} />
      <Box mt={10}>
        <SimpleGrid columns={1} spacing={6} w="full">
          {centreClasses.map((centre) => (
            <VStack key={centre.centreName} alignItems="flex-start" spacing={0}>
              <Heading size="md" mb={0}>
                {centre.centreName}
              </Heading>
              <Text mt={1} fontSize="sm" color="gray.600">
                {centre.address}
              </Text>
              <HStack mt={4} spacing={4} align="stretch" flexWrap="wrap">
                {centre.timeSlots.map((slot) => (
                  <Box
                    key={slot.classCode}
                    p={4}
                    borderWidth="2px"
                    borderRadius="md"
                    borderColor="gray.200"
                    bg={
                      selectedClasses.find(
                        (cls) => cls.classCode === slot.classCode
                      )
                        ? "#E7EDFF"
                        : selectedClass?.classCode === slot.classCode
                        ? "#E7EDFF"
                        : "white"
                    }
                    cursor={
                      slot.availability === "full" ? "not-allowed" : "pointer"
                    }
                    opacity={slot.availability === "full" ? 0.6 : 1}
                    onClick={
                      slot.availability !== "full"
                        ? () => handleSelectClass(slot)
                        : undefined
                    }
                    _hover={{
                      borderColor:
                        slot.availability === "available"
                          ? "#6D8CE9"
                          : undefined,
                      borderWidth: 2,
                      transition: "all 0.2s",
                    }}
                    position="relative"
                    height="fit-content"
                  >
                    <VStack alignItems="flex-start" spacing={0}>
                      <VStack
                        align="start"
                        spacing={1}
                        {...(selectedClass?.classCode === slot.classCode
                          ? {
                              fontWeight: 700,
                              color: "#6D8CE9",
                            }
                          : {
                              fontWeight: 400,
                              color: "#4A4A4A",
                            })}
                      >
                        <HStack
                          alignItems="center"
                          justifyContent="flex-start"
                          w="full"
                          spacing={2}
                        >
                          <Text>
                            {slot.day
                              .split(",")
                              .map((day) => {
                                const dayMap = {
                                  Mon: "Monday",
                                  Tue: "Tuesday",
                                  Wed: "Wednesday",
                                  Thu: "Thursday",
                                  Fri: "Friday",
                                  Sat: "Saturday",
                                  Sun: "Sunday",
                                };
                                return (
                                  dayMap[day.trim() as keyof typeof dayMap] ||
                                  day.trim()
                                );
                              })
                              .join(", ")}
                          </Text>
                          {slot.availability !== "available" && (
                            <Badge
                              colorScheme={
                                slot.availability === "full" ? "red" : "yellow"
                              }
                            >
                              {slot.availability}
                            </Badge>
                          )}
                        </HStack>
                        <Text whiteSpace="nowrap">
                          {slot.startTime} - {slot.endTime}
                        </Text>
                      </VStack>
                      {selectedClass?.classCode === slot.classCode &&
                        slot.upcomingSessions?.length > 0 && (
                          <>
                            <Divider my={4} borderColor="#6D8CE9" />
                            <Box>
                              <Text fontSize="sm" color="gray.600">
                                Select start date
                                <Text as="span" color="red.500">
                                  *
                                </Text>
                              </Text>
                              <Select
                                placeholder="Select start date"
                                value={selectedDate || ""}
                                onChange={(e) =>
                                  setSelectedDate(e.target.value)
                                }
                                size="sm"
                                onClick={(e) => e.stopPropagation()}
                                mt={4}
                              >
                                {slot.upcomingSessions?.map((date) => (
                                  <option key={date} value={date}>
                                    {convertToReadableDate(date)}
                                  </option>
                                ))}
                              </Select>
                              <Button
                                mt={4}
                                size="sm"
                                colorScheme="blue"
                                isDisabled={!selectedDate}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (selectedDate) {
                                    handleAddClass({
                                      ...selectedClass,
                                      selectedStartDate: selectedDate,
                                    });

                                    setSelectedClass(null);
                                    setSelectedDate(null);
                                  }
                                }}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </>
                        )}
                    </VStack>
                  </Box>
                ))}
              </HStack>
            </VStack>
            // <Card key={centre.centreName} variant="outline">
            //   <CardHeader>
            //     <Heading size="md">{centre.centreName}</Heading>
            //     <Text mt={2} fontSize="sm" color="gray.600">
            //       {centre.address}
            //     </Text>
            //   </CardHeader>
            //   <CardBody>
            //
            //   </CardBody>
            // </Card>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default ClassAvailabilitySection;
