import React, { useEffect, createContext, useContext, useState } from "react";
import {
  Box,
  VStack,
  Text,
  Center,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
  useSteps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEnrolmentStatus } from "../api/getEnrolmentStatus";
import { EnrolmentStatusResponseDto } from "../../../../shared/build/types/enrolment.dto";

interface EnrolmentStatus extends EnrolmentStatusResponseDto {}

interface EnrolmentContextType {
  enrolmentStatus: EnrolmentStatus | null;
  loading: boolean;
  error: Error | null;
}

const EnrolmentContext = createContext<EnrolmentContextType | undefined>(
  undefined
);

export const useEnrolmentStatus = () => {
  const context = useContext(EnrolmentContext);
  if (context === undefined) {
    throw new Error(
      "useEnrolmentStatus must be used within an EnrolmentLayout"
    );
  }
  return context;
};

interface EnrolmentLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  currentStep?: number;
  steps?: Array<{ title: string; description?: string }>;
}

type Orientation = "vertical" | "horizontal";

const EnrolmentLayout: React.FC<EnrolmentLayoutProps> = ({
  children,
  title,
  description,
  currentStep,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams();

  const [enrolmentStatus, setEnrolmentStatus] =
    useState<EnrolmentStatus | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const stepperOrientation: Orientation | undefined = useBreakpointValue({
    base: "vertical",
    md: "horizontal",
  });

  const steps = [
    { title: "Enter Email", description: "Begin enrolment" },
    { title: "Course Selection", description: "Choose your courses" },
    { title: "Payment Details", description: "Make payment" },
    { title: "Student Details", description: "Complete your registration" },
  ];

  useEffect(() => {
    // Check for empty slug on pages that require it
    if (
      ["/course-selection", "/payment-details"].some((path) =>
        location.pathname.includes(path)
      ) &&
      !slug
    ) {
      navigate("/");
      return;
    }

    // return instead of redirecting to allow for /student-details to be used without a slug
    if (!slug) return;

    // Check enrolment status if slug exists
    (async () => {
      setLoading(true);
      try {
        const result = await getEnrolmentStatus(slug);
        setEnrolmentStatus(result);

        if (result.status === "completed") {
          navigate(`/success`);
          return;
        }

        switch (result.currentStep) {
          case "course_selection":
            navigate(`/course-selection/${slug}`);
            break;
          case "payment":
            navigate(`/payment-details/${slug}`);
            break;
          case "registration":
            navigate(`/student-details/${slug}`);
            break;
          case "enter_email":
            navigate("/");
            break;
        }
      } catch (error) {
        console.error("Error checking enrolment status:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    })();
  }, [slug, location.pathname, navigate]);

  return (
    <EnrolmentContext.Provider value={{ enrolmentStatus, loading, error }}>
      <Center>
        <Box
          w="100%"
          minWidth="360px"
          maxWidth="1200px"
          px={{ base: 6, md: 8 }}
        >
          <VStack alignItems="flex-start" spacing={6}>
            {currentStep && (
              <Box w="100%" mt={{ base: 16, md: 20 }}>
                <Stepper
                  size={{ base: "lg", md: "lg" }}
                  index={currentStep - 1}
                  orientation={stepperOrientation}
                  gap={{ base: 0, md: 4 }} // Adjust the gap between steps for horizontal orientation"0"
                >
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>
                      <Box
                        flexShrink="0"
                        pb={index < steps.length - 1 ? { base: 8, md: 0 } : 0}
                      >
                        <StepTitle>{step.title}</StepTitle>
                        {step.description && (
                          <StepDescription>{step.description}</StepDescription>
                        )}
                      </Box>
                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            )}
            {title && (
              <Text fontSize="36px" fontWeight={700} lineHeight="100%" mt={12}>
                {title}
              </Text>
            )}
            {description && (
              <Text fontSize="18px" fontWeight={400} lineHeight="140%" mt={5}>
                {description}
              </Text>
            )}
            {children}
          </VStack>
        </Box>
      </Center>
    </EnrolmentContext.Provider>
  );
};

export default EnrolmentLayout;
