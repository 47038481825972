import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Select,
  HStack,
  Tooltip,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { getCourseSelectionOptions } from "../api/getCourseSelectionOptions";
import { listClassAvailability } from "../api/listClassAvailability";
import { submitCourseSelection } from "../api/submitCourseSelection";
import EnrolmentLayout from "../../lib/components/EnrolmentLayout";
import PagedFlowLayout from "../../student-details/components/PagedFlowLayout";
import PagedFlowLayoutFormSection from "../../student-details/components/PagedFlowLayoutFormSection";
import PagedFlowLayoutHeader from "../../student-details/components/PagedFlowLayoutHeader";
import ClassAvailabilitySection from "./ClassAvailabilitySection";
import SelectedClassesSection from "./SelectedClassesSection";
import {
  ClassMetadataResponseDto,
  CourseSearchResponseDto,
} from "../../../../shared/build/types/salesforce.dto";

export interface CourseOptions extends ClassMetadataResponseDto {}

export interface ClassAvailability extends CourseSearchResponseDto {}

export interface ClassTimeSlot
  extends Omit<ClassAvailability, "upcomingSessions"> {
  selectedStartDate: string;
}

const CourseSelectionPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { slug } = useParams<{ slug: string }>();

  const [courseOptions, setCourseOptions] = useState<CourseOptions | null>(
    null
  );
  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [classAvailabilityLevel, setClassAvailabilityLevel] =
    useState<string>("");
  const [classAvailabilityCourse, setClassAvailabilityCourse] =
    useState<string>("");
  const [classAvailability, setClassAvailability] = useState<
    ClassAvailability[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState<ClassTimeSlot[]>([]);

  useEffect(() => {
    const fetchCourseOptions = async () => {
      try {
        const options = await getCourseSelectionOptions();
        setCourseOptions(options);
      } catch (error) {
        toast({
          title: "Error fetching Mind Stretcher courses",
          description: "Please try again later",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchCourseOptions();
  }, [toast]);

  // Reset selected course when level changes
  useEffect(() => {
    setSelectedCourse("");
  }, [selectedLevel]);

  const handleContinueToPayment = async () => {
    try {
      if (!slug) {
        toast({
          title: "Error",
          description: "Missing enrolment slug",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      await submitCourseSelection(slug, selectedClasses);
      navigate(`/payment-details/${slug}`);
    } catch (error) {
      toast({
        title: "Error submitting course selection",
        description: "Please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearchSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedLevel || !selectedCourse) {
      toast({
        title: "Please select both level and course",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const classAvailability = await listClassAvailability({
        levelTitle: selectedLevel,
        courseName: selectedCourse,
      });
      setClassAvailability(classAvailability);
      setClassAvailabilityLevel(selectedLevel);
      setClassAvailabilityCourse(selectedCourse);
    } catch (error) {
      toast({
        title: "Error fetching class availability",
        description: "Please try again later",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClass = (classInfo: any) => {
    if (
      !selectedClasses.some((cls) => cls.classCode === classInfo.classCode) &&
      classInfo.selectedStartDate
    ) {
      const newSelectedClass: ClassTimeSlot = {
        ...classInfo,
        level: selectedLevel,
        course: selectedCourse,
      };
      setSelectedClasses([...selectedClasses, newSelectedClass]);
      setClassAvailability(null);
    }
  };

  const handleRemoveClass = (classCode: string) => {
    setSelectedClasses(
      selectedClasses.filter((cls) => cls.classCode !== classCode)
    );
  };

  return (
    <EnrolmentLayout currentStep={2}>
      <PagedFlowLayout mb={0}>
        <PagedFlowLayoutHeader
          title="Course Selection"
          description="Please select your preferred level and course to see a list of classes we have available."
        />
        <PagedFlowLayoutFormSection>
          <VStack w="full" spacing={6} align="flex-start">
            <form onSubmit={handleSearchSubmit} style={{ width: "100%" }}>
              <HStack spacing={4} width="100%" alignItems="flex-end">
                <FormControl isRequired flexGrow={1} flexBasis={0}>
                  <FormLabel>Level</FormLabel>
                  <Select
                    placeholder="Select level"
                    value={selectedLevel}
                    onChange={(e) => setSelectedLevel(e.target.value)}
                  >
                    {courseOptions?.levelTitles?.map((level: string) => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Tooltip
                  label="Please select a level first"
                  isDisabled={!!selectedLevel}
                  hasArrow
                  placement="top"
                >
                  <FormControl
                    isRequired
                    isDisabled={!selectedLevel}
                    flexGrow={3}
                    flexBasis={0}
                  >
                    <FormLabel>Course</FormLabel>
                    <Select
                      placeholder="Select course"
                      value={selectedCourse}
                      onChange={(e) => setSelectedCourse(e.target.value)}
                    >
                      {selectedLevel &&
                        courseOptions?.levelToCourseNames[selectedLevel]?.map(
                          (course: string) => (
                            <option key={course} value={course}>
                              {course}
                            </option>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Tooltip>

                <Button
                  type="submit"
                  variant="ms-solid"
                  colorScheme="blue"
                  width="fit-content"
                  mt={4}
                  isDisabled={!selectedLevel || !selectedCourse || isLoading}
                >
                  {isLoading ? "Searching..." : "Search"}
                </Button>
              </HStack>
            </form>
          </VStack>
          {classAvailability && (
            <Box mt={12}>
              <ClassAvailabilitySection
                selectedClasses={selectedClasses}
                classAvailability={classAvailability ?? []}
                classAvailabilityLevel={classAvailabilityLevel}
                classAvailabilityCourse={classAvailabilityCourse}
                classAvailabilityFilterOptions={courseOptions}
                handleAddClass={handleAddClass}
              />
            </Box>
          )}
        </PagedFlowLayoutFormSection>
      </PagedFlowLayout>
      <SelectedClassesSection
        selectedClasses={selectedClasses}
        onRemoveClass={handleRemoveClass}
        onContinueToPayment={handleContinueToPayment}
      />
    </EnrolmentLayout>
  );
};

export default CourseSelectionPage;
