import { ClassMetadataResponseDto } from "../../../../shared/build/types/salesforce.dto";

import { ApiService } from "../../student-details/api";
import camelcaseKeys from "camelcase-keys";
import _ from "lodash";

export const getCourseSelectionOptions = async () => {
  const { data } = await ApiService.get<ClassMetadataResponseDto>(
    `/enrolments/course-selection/options`
  );

  return data;
};
