import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import PagedFlowLayout from "./PagedFlowLayout";
import PagedFlowLayoutHeader from "./PagedFlowLayoutHeader";
import PagedFlowLayoutNavSection from "./PagedFlowLayoutNavSection";
import PagedFlowLayoutFormSection from "./PagedFlowLayoutFormSection";
import { MSPhoneInput } from "./MSPhoneInput";
import _ from "lodash";
import { RegistrationFormProps } from "../../RegistrationForm";

type ParentGuardianInfoProps = {
  primaryContactRelationship: string;
  primaryContactFullName: string;
  primaryContactMobileNumber: string;
  primaryContactEmail: string;
  primaryContactOccupation?: string;
  secondaryContactRelationship?: string;
  secondaryContactFullName?: string;
  secondaryContactMobileNumber?: string;
  secondaryContactEmail?: string;
  secondaryContactOccupation?: string;
};

const schema = z.object({
  primaryContactRelationship: z.string(),
  primaryContactFullName: z.string(),
  primaryContactMobileNumber: z
    .string({
      message: "Please enter a valid phone number",
    })
    .refine(
      (val) => {
        if (_.startsWith(val, "+65")) {
          const eightDigitsLong = val.length === 11; // includes country code
          const startsWithEightOrNine =
            _.startsWith(val, "+658") || _.startsWith(val, "+659");
          return eightDigitsLong && startsWithEightOrNine;
        }
        return true;
      },
      {
        message: "Please enter a valid phone number.",
      }
    ),
  primaryContactEmail: z
    .string()
    .email({ message: "Please enter a valid email address" }),
  primaryContactOccupation: z.string(),
  secondaryContactRelationship: z.string(),
  secondaryContactFullName: z.string(),
  secondaryContactMobileNumber: z
    .string({
      message: "Please enter a valid phone number",
    })
    .refine(
      (val) => {
        if (_.startsWith(val, "+65")) {
          const eightDigitsLong = val.length === 11; // includes country code
          const startsWithEightOrNine =
            _.startsWith(val, "+658") || _.startsWith(val, "+659");
          return eightDigitsLong && startsWithEightOrNine;
        }
        return true;
      },
      { message: "Please enter a valid phone number." }
    ),
  secondaryContactEmail: z
    .string()
    .email({ message: "Please enter a valid email address" })
    .optional(),
  secondaryContactOccupation: z.string().optional(),
});

function ParentGuardianInfo({
  currPage,
  pageInFlow,
  nextPage,
  registrationData,
  updateRegistration,
}: {
  currPage: number;
  pageInFlow: number;
  nextPage: () => void;
  registrationData: RegistrationFormProps;
  updateRegistration: (pageData: any) => void;
}) {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    resetField,
  } = useForm<ParentGuardianInfoProps>({
    resolver: zodResolver(schema),
    defaultValues: registrationData,
  });

  const onSubmit = async (data: ParentGuardianInfoProps) => {
    console.log("data", data);
    // Update parent object
    updateRegistration(data);

    nextPage();
  };

  return (
    <VStack alignItems="flex-start">
      {/* <Text fontSize="36px" fontWeight={700} lineHeight="100%" mt={12}>
        Registration Form
      </Text>
      <Text fontSize="18px" fontWeight={400} lineHeight="140%" mt={5}>
        Welcome to Mind Stretcher! To get started, please take a moment to
        complete the registration form. This form is designed to be quick and
        straightforward, asking for key details such as your child's
        information, your contact details, and preferred class schedules. By
        filling it out, you'll help us ensure a smooth and personalized
        experience for your child. Thank you for choosing us to support your
        child's educational journey!
      </Text> */}
      <PagedFlowLayout>
        <SlideFade in={currPage === pageInFlow} offsetX="80px" offsetY="0px">
          <PagedFlowLayoutHeader
            title="Parent/Guardian Information"
            subtitle="Part 1 of 4"
          />
          <PagedFlowLayoutFormSection>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack
                gap={5}
                alignItems={"flex-start"}
                maxWidth={{ base: "100%", lg: "50%" }}
              >
                <Text fontSize="20px" fontWeight={700}>
                  Primary Contact
                </Text>
                <FormControl
                  isInvalid={!!errors.primaryContactRelationship}
                  isRequired
                >
                  <FormLabel htmlFor="primaryContactRelationship">
                    Relationship to student
                  </FormLabel>
                  <Select
                    placeholder="Select Relationship"
                    {...register("primaryContactRelationship")}
                  >
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="guardian">Guardian</option>
                  </Select>
                  {errors.primaryContactRelationship && (
                    <FormErrorMessage>
                      {errors.primaryContactRelationship.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.primaryContactFullName}
                  isRequired
                >
                  <FormLabel htmlFor="primaryContactFullName">
                    Full Name
                  </FormLabel>
                  <Input
                    id="primaryContactFullName"
                    {...register("primaryContactFullName")}
                  />
                  {errors.primaryContactFullName && (
                    <FormErrorMessage>
                      {errors.primaryContactFullName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.primaryContactMobileNumber}
                  isRequired
                >
                  <FormLabel htmlFor="primaryContactMobileNumber">
                    Mobile Number
                  </FormLabel>
                  <Controller
                    control={control}
                    name="primaryContactMobileNumber"
                    render={({ field: { onChange, value } }) => (
                      <MSPhoneInput
                        id="primaryContactMobileNumber"
                        value={value}
                        onChange={(v) => {
                          onChange(v);
                        }} // map the component onChange to use the react-hook-form onChange
                      />
                    )}
                  />
                  {errors.primaryContactMobileNumber && (
                    <FormErrorMessage>
                      {errors.primaryContactMobileNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.primaryContactEmail}
                  isRequired
                >
                  <FormLabel htmlFor="primaryContactEmail">Email</FormLabel>
                  <Input
                    id="primaryContactEmail"
                    {...register("primaryContactEmail")}
                  />
                  {errors.primaryContactEmail && (
                    <FormErrorMessage>
                      {errors.primaryContactEmail.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.primaryContactOccupation}>
                  <FormLabel htmlFor="primaryContactOccupation">
                    Occupation
                  </FormLabel>
                  <Input
                    id="primaryContactOccupation"
                    {...register("primaryContactOccupation")}
                  />
                  {errors.primaryContactOccupation && (
                    <FormErrorMessage>
                      {errors.primaryContactOccupation.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <VStack alignItems="flex-start" mt={5} gap={1}>
                  <Text fontSize="20px" fontWeight={700}>
                    Secondary Contact
                  </Text>
                  <Text fontSize="16px" fontWeight={400}>
                    This will help us make sure all urgent communications about
                    your child is conveyed in a timely manner. We appreciate you
                    taking the time to fill this in.
                  </Text>
                </VStack>
                <FormControl
                  isInvalid={!!errors.secondaryContactRelationship}
                  isRequired
                >
                  <FormLabel htmlFor="secondaryContactRelationship">
                    Relationship to student
                  </FormLabel>
                  <Select
                    placeholder="Select Relationship"
                    {...register("secondaryContactRelationship")}
                  >
                    <option value="father">Father</option>
                    <option value="mother">Mother</option>
                    <option value="guardian">Guardian</option>
                  </Select>
                  {errors.secondaryContactRelationship && (
                    <FormErrorMessage>
                      {errors.secondaryContactRelationship.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.secondaryContactFullName}
                  isRequired
                >
                  <FormLabel htmlFor="secondaryContactFullName">
                    Full Name
                  </FormLabel>
                  <Input
                    id="secondaryContactFullName"
                    {...register("secondaryContactFullName")}
                  />
                  {errors.secondaryContactFullName && (
                    <FormErrorMessage>
                      {errors.secondaryContactFullName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.secondaryContactMobileNumber}
                  isRequired
                >
                  <FormLabel htmlFor="secondaryContactMobileNumber">
                    Mobile Number
                  </FormLabel>
                  <Controller
                    control={control}
                    name="secondaryContactMobileNumber"
                    render={({ field: { onChange, value } }) => (
                      <MSPhoneInput
                        id="secondaryContactMobileNumber"
                        value={value}
                        onChange={(v) => {
                          onChange(v);
                        }} // map the component onChange to use the react-hook-form onChange
                      />
                    )}
                  />
                  {errors.secondaryContactMobileNumber && (
                    <FormErrorMessage>
                      {errors.secondaryContactMobileNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  isInvalid={!!errors.secondaryContactEmail}
                  isRequired
                >
                  <FormLabel htmlFor="secondaryContactEmail">Email</FormLabel>
                  <Input
                    id="secondaryContactEmail"
                    {...register("secondaryContactEmail")}
                  />
                  {errors.secondaryContactEmail && (
                    <FormErrorMessage>
                      {errors.secondaryContactEmail.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.secondaryContactOccupation}>
                  <FormLabel htmlFor="secondaryContactOccupation">
                    Occupation
                  </FormLabel>
                  <Input
                    id="secondaryContactOccupation"
                    {...register("secondaryContactOccupation")}
                  />
                  {errors.secondaryContactOccupation && (
                    <FormErrorMessage>
                      {errors.secondaryContactOccupation.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </VStack>
              <PagedFlowLayoutNavSection mt={12}>
                <Button
                  type="submit"
                  variant="ms-solid"
                  rightIcon={<ArrowForwardIcon pt="1px" />}
                >
                  Continue
                </Button>
              </PagedFlowLayoutNavSection>
            </form>
          </PagedFlowLayoutFormSection>
        </SlideFade>
      </PagedFlowLayout>
    </VStack>
  );
}

export default ParentGuardianInfo;
