import { Box } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayout({
  children,
  ...styleProps
}: { children: React.ReactNode } & React.ComponentProps<typeof Box>) {
  return (
    <Box
      width="100%"
      px={{ base: 6, md: 20 }}
      py={{ base: 6, md: 16 }}
      borderRadius="20px"
      backgroundColor="#F7F9FF"
      mt={12}
      mb={16}
      {...styleProps}
    >
      {children}
    </Box>
  );
}

export default PagedFlowLayout;
