import { Box, Button, Center, SlideFade } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";

function PagedFlow({
  page,
  children,
}: {
  page: number;
  children: React.ReactNode;
}) {
  if (!Array.isArray(children)) {
    throw new Error("PagedFlow children must be an array");
  }

  return (
    <Box w="100%">
      {children.map(
        (child, index) => page === index && <Box key={index}>{child}</Box>
      )}
    </Box>
  );
}

export default PagedFlow;
