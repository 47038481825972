import { EnrolmentStatusResponseDto } from "../../../../shared/build/types/enrolment.dto";
import { CourseEnrolmentDetails } from "../../../../shared/build/types/enrolment.dto";
import { ClassTimeSlot } from "../components/CourseSelectionPage";
import { ApiService } from "../../student-details/api";

export const submitCourseSelection = async (
  slug: string,
  selectedClasses: ClassTimeSlot[]
): Promise<EnrolmentStatusResponseDto> => {
  const requestBody = {
    courses: selectedClasses.map((cls) => ({
      courseCode: cls.classCode,
      startDate: cls.selectedStartDate,
    })) as CourseEnrolmentDetails[],
  };

  const { data } = await ApiService.post<EnrolmentStatusResponseDto>(
    `/enrolments/${slug}/course-selection`,
    requestBody
  );

  return data;
};
