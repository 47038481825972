import React from "react";
import "./App.css";

import { Box, ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import theme from "./theme";
import FormHeader from "./student-details/components/FormHeader";
import CreateEnrolmentPage from "./create-enrolment/components/CreateEnrolmentPage";
import CourseSelectionPage from "./course-selection/components/CourseSelectionPage";
import RegistrationForm from "./RegistrationForm";
import SuccessPage from "./SuccessPage";
import { TermsAndConditionsPage } from "./TermsAndConditionsPage";
import { DataProtectionPolicyPage } from "./DataProtectionPolicyPage";

const LazyEnrolmentPaymentPage = React.lazy(
  () => import("./enrolment-payment/components/EnrolmentPaymentPage")
);

export const isOldRegistrationSite =
  window.location.hostname === "register.mindstretcher.com" ||
  window.location.hostname === "register.masterspeakers.com";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box className="App">
          <FormHeader />
          <Routes>
            {isOldRegistrationSite ? (
              <>
                <Route path="/" element={<RegistrationForm />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route
                  path="/data-protection-policy"
                  element={<DataProtectionPolicyPage />}
                />
                <Route path="/success" element={<SuccessPage />} />
              </>
            ) : (
              <>
                <Route path="/" element={<CreateEnrolmentPage />} />
                <Route
                  path="/course-selection"
                  element={<CourseSelectionPage />}
                />
                <Route
                  path="/course-selection/:slug"
                  element={<CourseSelectionPage />}
                />
                <Route
                  path="/payment-details"
                  element={
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <LazyEnrolmentPaymentPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/payment-details/:slug"
                  element={
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <LazyEnrolmentPaymentPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/student-details/:slug"
                  element={<RegistrationForm />}
                />
                <Route path="/student-details" element={<RegistrationForm />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route
                  path="/data-protection-policy"
                  element={<DataProtectionPolicyPage />}
                />
                <Route path="/success" element={<SuccessPage />} />
              </>
            )}
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default App;
