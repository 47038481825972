import React, { useRef } from "react";
import {
  Button,
  Box,
  VStack,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

type CenterData = {
  [region: string]: string[];
};

interface CenterFilterComboBoxProps {
  /** Currently selected centers */
  selectedCenters: string[];
  /** Callback when selections change */
  onCenterChange: (centers: string[]) => void;
  /** Center options mapping from region to center names */
  centerOptions: CenterData;
  /** Custom placeholder text */
  placeholder?: string;
  /** Width of the combobox */
  width?: string | number;
  /** Disable the combobox */
  isDisabled?: boolean;
}

export const CenterFilterComboBox: React.FC<CenterFilterComboBoxProps> = ({
  selectedCenters = [],
  onCenterChange,
  centerOptions,
  placeholder = "Select Location",
  width,
  isDisabled = false,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleParentChange = (parent: string) => {
    const childLocations = centerOptions[parent];
    const areAllChildrenSelected = childLocations.every((child) =>
      selectedCenters.includes(child)
    );

    let newSelections: string[];
    if (areAllChildrenSelected) {
      // Remove all children of this parent
      newSelections = selectedCenters.filter(
        (center) => !childLocations.includes(center)
      );
    } else {
      // Add all unselected children
      const centersToAdd = childLocations.filter(
        (center) => !selectedCenters.includes(center)
      );
      newSelections = [...selectedCenters, ...centersToAdd];
    }

    onCenterChange(newSelections);
  };

  const handleChildChange = (center: string) => {
    const newSelections = selectedCenters.includes(center)
      ? selectedCenters.filter((c) => c !== center)
      : [...selectedCenters, center];
    onCenterChange(newSelections);
  };

  const isParentSelected = (parent: string): boolean => {
    const childLocations = centerOptions[parent];
    return childLocations.every((child) => selectedCenters.includes(child));
  };

  const isParentIndeterminate = (parent: string): boolean => {
    const childLocations = centerOptions[parent];
    const selectedCount = childLocations.filter((child) =>
      selectedCenters.includes(child)
    ).length;
    return selectedCount > 0 && selectedCount < childLocations.length;
  };

  // For some reason the popover content is not autofocused, so need to add this
  // so that the popover will close when clicking outside
  const popoverContentRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: popoverContentRef,
    handler: onClose,
  });

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      closeOnBlur={true}
      closeOnEsc={true}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Button
          onClick={() => {
            onToggle();
          }}
          rightIcon={<ChevronDownIcon />}
          width="full"
          textAlign="left"
          variant="outline"
          isDisabled={isDisabled}
          fontWeight={400}
          backgroundColor="white"
        >
          {selectedCenters.length > 0
            ? `${selectedCenters.length} location${
                selectedCenters.length > 1 ? "s" : ""
              } selected`
            : placeholder}
        </Button>
      </PopoverTrigger>

      <PopoverContent ref={popoverContentRef}>
        <PopoverBody maxHeight="400px" overflow="auto" padding={0}>
          <VStack align="stretch" spacing={0}>
            {Object.entries(centerOptions)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([parent, children]) => (
                <Box key={parent} padding={2}>
                  <Checkbox
                    isChecked={isParentSelected(parent)}
                    isIndeterminate={isParentIndeterminate(parent)}
                    onChange={() => handleParentChange(parent)}
                    fontWeight="medium"
                  >
                    {parent}
                  </Checkbox>

                  <VStack
                    align="stretch"
                    marginLeft={6}
                    spacing={1}
                    marginTop={1}
                  >
                    {children.map((child) => (
                      <Checkbox
                        key={child}
                        isChecked={selectedCenters.includes(child)}
                        onChange={() => handleChildChange(child)}
                        size="sm"
                      >
                        <Text fontSize="sm">{child}</Text>
                      </Checkbox>
                    ))}
                  </VStack>
                </Box>
              ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CenterFilterComboBox;
