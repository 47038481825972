import React, { useRef } from "react";
import {
  Button,
  Box,
  VStack,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const DAY_OPTIONS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface DayFilterComboBoxProps {
  /** Currently selected days */
  selectedDays: string[];
  /** Callback when selections change */
  onDayChange: (days: string[]) => void;
  /** Custom placeholder text */
  placeholder?: string;
  /** Width of the combobox */
  width?: string | number;
  /** Disable the combobox */
  isDisabled?: boolean;
}

export const DayFilterComboBox: React.FC<DayFilterComboBoxProps> = ({
  selectedDays = [],
  onDayChange,
  placeholder = "Select Days",
  width,
  isDisabled = false,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleDayChange = (day: string) => {
    const newSelections = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    onDayChange(newSelections);
  };

  // For some reason the popover content is not autofocused, so need to add this
  // so that the popover will close when clicking outside
  const popoverContentRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: popoverContentRef,
    handler: onClose,
  });

  return (
    <Box width={width ?? "fit-content"}>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={true}
        closeOnEsc={true}
        placement="bottom-start"
        isLazy
        autoFocus={false}
      >
        <PopoverTrigger>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            rightIcon={<ChevronDownIcon />}
            width="full"
            textAlign="left"
            variant="outline"
            isDisabled={isDisabled}
            fontWeight={400}
            backgroundColor="white"
          >
            {selectedDays.length > 0
              ? `${selectedDays.length} day${
                  selectedDays.length > 1 ? "s" : ""
                } selected`
              : placeholder}
          </Button>
        </PopoverTrigger>

        <PopoverContent width={width} ref={popoverContentRef}>
          <PopoverBody p={0}>
            <VStack align="stretch" spacing={0}>
              {DAY_OPTIONS.map((day) => (
                <Checkbox
                  key={day}
                  isChecked={selectedDays.includes(day)}
                  onChange={() => handleDayChange(day)}
                  p={2}
                >
                  {day}
                </Checkbox>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DayFilterComboBox;
