import { BaseEnrolmentResponseDto } from "../../../../shared/build/types/enrolment.dto";
import { ApiService } from "../../student-details/api";

export const startEnrolment = async (
  startEnrolmentDto: any
): Promise<BaseEnrolmentResponseDto> => {
  const { data } = await ApiService.post<BaseEnrolmentResponseDto>(
    `/enrolments/start`,
    startEnrolmentDto
  );

  return data;
};
