import { Box, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";

function PagedFlowLayoutFormSection({
  mt,
  children,
}: {
  mt?: number;
  children: React.ReactNode;
}) {
  return <Box mt={mt ?? 10}>{children}</Box>;
}

export default PagedFlowLayoutFormSection;
