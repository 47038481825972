import React from "react";
import {
  Box,
  Heading,
  Container,
  Button,
  VStack,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ApiService } from "../../student-details/api";
import EnrolmentLayout from "../../lib/components/EnrolmentLayout";
import PagedFlowLayoutFormSection from "../../student-details/components/PagedFlowLayoutFormSection";
import PagedFlowLayoutNavSection from "../../student-details/components/PagedFlowLayoutNavSection";
import PagedFlowLayout from "../../student-details/components/PagedFlowLayout";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { startEnrolment } from "../api/startEnrolment";
import PagedFlowLayoutHeader from "../../student-details/components/PagedFlowLayoutHeader";

const schema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

type FormData = z.infer<typeof schema>;

const CreateEnrolmentPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });
  const toast = useToast();

  const onSubmit = async (data: FormData) => {
    try {
      const enrolment = await startEnrolment(data);
      navigate(`/course-selection/${enrolment.slug}`);
    } catch (error) {
      console.error("Error creating enrollment:", error);
      toast({
        title: "Unable to create enrollment",
        description:
          "Please try again later or contact if the problem persists.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <EnrolmentLayout
      title="Mind Stretcher Enrolment Form"
      description="Hi there! To get started with the enrolment process, we just need your email address. This helps us keep track of your enrolment and send you important updates about your child's classes. It only takes a moment, and then you'll be on your way to exploring our courses. Thanks for beginning this exciting educational journey with us!"
    >
      <PagedFlowLayout>
        <PagedFlowLayoutHeader
          title="Get Started"
          description="Please enter your email address to get started."
        />
        <PagedFlowLayoutFormSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              gap={5}
              alignItems={"flex-start"}
              maxWidth={{ base: "100%", lg: "50%" }}
            >
              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  placeholder="Enter your email"
                  {...register("email")}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <Box mt={12}>
              <Button
                type="submit"
                variant="ms-solid"
                rightIcon={<ArrowForwardIcon pt="1px" />}
                isLoading={isSubmitting}
              >
                Start Enrolment
              </Button>
            </Box>
          </form>
        </PagedFlowLayoutFormSection>
      </PagedFlowLayout>
    </EnrolmentLayout>
  );
};

export default CreateEnrolmentPage;
