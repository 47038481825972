import { ApiService } from "../../student-details/api";
import camelcaseKeys from "camelcase-keys";
import _ from "lodash";
import { CourseSearchResponseDto } from "../../../../shared/build/types/salesforce.dto";

export const listClassAvailability = async ({
  levelTitle,
  courseName,
}: {
  levelTitle: string;
  courseName: string;
}) => {
  const { data } = await ApiService.get<CourseSearchResponseDto[]>(
    `/enrolments/course-selection/search`,
    {
      params: {
        levelTitle,
        courseName,
      },
    }
  );

  return data ? camelcaseKeys(data, { deep: true }) : data;
};
