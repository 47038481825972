import { EnrolmentStatusResponseDto } from "../../../../shared/build/types/enrolment.dto";
import { ApiService } from "../../student-details/api";

export const getEnrolmentStatus = async (
  slug: string
): Promise<EnrolmentStatusResponseDto> => {
  const { data } = await ApiService.get<EnrolmentStatusResponseDto>(
    `/enrolments/${slug}`
  );

  return data;
};
