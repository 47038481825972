import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";

import PagedFlowLayout from "./PagedFlowLayout";
import PagedFlowLayoutHeader from "./PagedFlowLayoutHeader";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import AutocompleteInput from "./AutocompleteInput";
import PagedFlowLayoutFormSection from "./PagedFlowLayoutFormSection";
import PagedFlowLayoutNavSection from "./PagedFlowLayoutNavSection";
import { useEffect, useState } from "react";

import { schools } from "../constants/schools";
import { RegistrationFormProps } from "../../RegistrationForm";
import _ from "lodash";
import { isValidSgUnitNumber } from "../utils/address";

type StudentAdditionalInfoProps = {
  studentAddressBlock?: string;
  studentAddressStreet?: string;
  studentAddressUnitNumber?: string;
  studentAddressPostalCode?: string;
  studentAddressOverseas?: string;
  academicYearRegisteringFor: string;
  studentSchool: string;
  studentLevel: string;
  studentStreamType?: string;
  studentStream?: string;
};

const schema = z.object({
  studentAddressBlock: z
    .string()
    .max(5, { message: "Block number must be 5 characters or less." })
    .optional(),
  studentAddressStreet: z.string().optional(),
  studentAddressUnitNumber: z
    .string()
    .refine(
      (val) => {
        if (!isValidSgUnitNumber(val)) {
          return false;
        }
        return true;
      },
      {
        message: "Please enter a valid unit number eg. #03-01",
      }
    )
    .optional(),
  studentAddressPostalCode: z
    .string()
    .length(6, { message: "Please enter a 6 digit postal code" })
    .optional(),
  studentAddressOverseas: z.string().optional(),
  academicYearRegisteringFor: z.string(),
  studentSchool: z.string(),
  studentLevel: z.string(),
  studentStreamType: z.string().optional(),
  studentStream: z.string().optional(),
});

function StudentAdditionalInfo({
  currPage,
  pageInFlow,
  nextPage,
  prevPage,
  registrationData,
  updateRegistration,
}: {
  currPage: number;
  pageInFlow: number;
  nextPage: () => void;
  prevPage: () => void;
  registrationData: RegistrationFormProps;
  updateRegistration: (pageData: any) => void;
}) {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    resetField,
  } = useForm<StudentAdditionalInfoProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...registrationData,
      // Hidden fields and fields with defaults need to be reset
      // studentAddressOverseas: undefined,
      // academicYearRegisteringFor: undefined,
      // studentStream: undefined,
    },
  });

  // Using watch as a hack to trigger re-render
  const watchStudentLevel = watch("studentLevel");
  const watchAcademicYear = watch("academicYearRegisteringFor");
  const watchStudentStreamType = watch("studentStreamType");

  // Set default value as checked if studentAddressOverseas is not undefined
  const [isOverseasAddress, setIsOverseasAddress] = useState(
    !_.isEmpty(registrationData.studentAddressOverseas) ? true : false
  );

  // Show the correct Stream option based on the student level
  useEffect(() => {
    switch (watchStudentLevel) {
      case "P1":
      case "P2":
      case "P3":
      case "P4":
      case "P5":
      case "P6":
        setValue("studentStreamType", "primary");
        break;
      case "S1":
      case "S2":
      case "S3":
      case "OL":
        setValue("studentStreamType", "secondary");
        break;
      default:
        setValue("studentStreamType", undefined);
        break;
    }
  }, [watchStudentLevel]);

  const onSubmit = async (data: StudentAdditionalInfoProps) => {
    // Handle form submission here
    console.log("date received", data);

    // TODO: Handle updating parent object
    updateRegistration(data);

    nextPage();
  };

  return (
    <PagedFlowLayout>
      <PagedFlowLayoutHeader
        title="Additional Student Information"
        subtitle="Part 3 of 4"
      />
      <SlideFade in={currPage === pageInFlow} offsetX="100px" offsetY="0px">
        <PagedFlowLayoutFormSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              gap={5}
              alignItems={"flex-start"}
              maxWidth={{ base: "100%", lg: "50%" }}
            >
              <Text fontSize="20px" fontWeight={700}>
                Address
              </Text>
              <Checkbox
                isChecked={isOverseasAddress}
                onChange={() => {
                  resetField("studentAddressBlock");
                  resetField("studentAddressStreet");
                  resetField("studentAddressUnitNumber");
                  resetField("studentAddressPostalCode");
                  resetField("studentAddressOverseas");
                  setIsOverseasAddress(!isOverseasAddress);
                }}
              >
                I do not have a Singapore Address
              </Checkbox>
              {isOverseasAddress ? (
                <FormControl
                  isInvalid={!!errors.studentAddressOverseas}
                  isRequired
                >
                  <FormLabel htmlFor="studentAddressOverseas">
                    Full Address
                  </FormLabel>
                  <Input
                    id="studentAddressOverseas"
                    {...register("studentAddressOverseas")}
                  />
                  {errors.studentAddressOverseas && (
                    <FormErrorMessage>
                      {errors.studentAddressOverseas.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              ) : (
                <>
                  <FormControl
                    isInvalid={!!errors.studentAddressBlock}
                    isRequired
                  >
                    <FormLabel htmlFor="studentAddressBlock">
                      Block / House Number
                    </FormLabel>
                    <Input
                      id="studentAddressBlock"
                      placeholder="60"
                      {...register("studentAddressBlock", {
                        onChange: (e) =>
                          setValue(
                            "studentAddressBlock",
                            _.toUpper(e.target.value)
                          ),
                      })}
                    />
                    {errors.studentAddressBlock && (
                      <FormErrorMessage>
                        {errors.studentAddressBlock.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.studentAddressStreet}
                    isRequired
                  >
                    <FormLabel htmlFor="studentAddressStreet">
                      Street Name
                    </FormLabel>
                    <Input
                      id="studentAddressStreet"
                      placeholder="Aria Street"
                      {...register("studentAddressStreet")}
                    />
                    {errors.studentAddressStreet && (
                      <FormErrorMessage>
                        {errors.studentAddressStreet.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.studentAddressUnitNumber}
                    isRequired
                  >
                    <FormLabel htmlFor="studentAddressUnitNumber">
                      Unit Number
                    </FormLabel>
                    <Input
                      id="studentAddressUnitNumber"
                      placeholder="#03-01"
                      {...register("studentAddressUnitNumber", {
                        onChange: (e) =>
                          setValue(
                            "studentAddressUnitNumber",
                            _.toUpper(e.target.value)
                          ),
                      })}
                    />
                    {errors.studentAddressUnitNumber && (
                      <FormErrorMessage>
                        {errors.studentAddressUnitNumber.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.studentAddressPostalCode}
                    isRequired
                  >
                    <FormLabel htmlFor="studentAddressPostalCode">
                      Postal Code
                    </FormLabel>
                    <Input
                      id="studentAddressPostalCode"
                      placeholder="602580"
                      {...register("studentAddressPostalCode")}
                    />
                    {errors.studentAddressPostalCode && (
                      <FormErrorMessage>
                        {errors.studentAddressPostalCode.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </>
              )}
              <Text fontSize="20px" fontWeight={700} mt={5}>
                School Details
              </Text>
              <FormControl
                isInvalid={!!errors.academicYearRegisteringFor}
                isRequired
              >
                <FormLabel htmlFor="academicYearRegisteringFor">
                  Academic Year Registering For
                </FormLabel>
                <Select {...register("academicYearRegisteringFor")}>
                  <option value="2025">2025</option>
                </Select>
                {errors.academicYearRegisteringFor && (
                  <FormErrorMessage>
                    {errors.academicYearRegisteringFor.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentSchool} isRequired>
                <FormLabel htmlFor="studentSchool">School</FormLabel>
                <AutocompleteInput
                  suggestions={schools}
                  setInputValue={(value) => setValue("studentSchool", value)}
                  inputProps={{
                    id: "studentSchool",
                    ...register("studentSchool"),
                  }}
                />
                {errors.studentSchool && (
                  <FormErrorMessage>
                    {errors.studentSchool.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.studentLevel} isRequired>
                <FormLabel htmlFor="studentLevel" mb={0}>
                  Level
                </FormLabel>
                <FormHelperText mt={0} mb={2}>
                  Please enter your child's level for {watchAcademicYear}
                </FormHelperText>
                <Controller
                  control={control}
                  name="studentLevel"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      placeholder="Select Level"
                      value={value}
                      onChange={(val) => {
                        resetField("studentStream");
                        onChange(val);
                      }}
                    >
                      <option value="N1">N1</option>
                      <option value="N2">N2</option>
                      <option value="K1">K1</option>
                      <option value="K2">K2</option>
                      <option value="P1">P1</option>
                      <option value="P2">P2</option>
                      <option value="P3">P3</option>
                      <option value="P4">P4</option>
                      <option value="P5">P5</option>
                      <option value="P6">P6</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                      <option value="OL">OL</option>
                    </Select>
                  )}
                />
                {errors.studentLevel && (
                  <FormErrorMessage>
                    {errors.studentLevel.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {watchStudentStreamType === "primary" ? (
                <FormControl isInvalid={!!errors.studentStream} isRequired>
                  <FormLabel htmlFor="studentStream">Stream</FormLabel>
                  <Select
                    placeholder="Select Stream"
                    {...register("studentStream")}
                  >
                    <option value="Mainstream">Mainstream</option>
                    <option value="GEP">GEP</option>
                  </Select>
                  {errors.studentStream && (
                    <FormErrorMessage>
                      {errors.studentStream.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              ) : watchStudentStreamType === "secondary" ? (
                <FormControl isInvalid={!!errors.studentStream} isRequired>
                  <FormLabel htmlFor="studentStream">Stream</FormLabel>
                  <Select
                    placeholder="Select Stream"
                    {...register("studentStream")}
                  >
                    <option value="G3 / Express">G3 / Express</option>
                    <option value="G2 / N(A)">G2 / N(A)</option>
                    <option value="IP (A-Level, IB / NUS Diploma)">
                      IP (A-Level, IB / NUS Diploma)
                    </option>
                  </Select>
                  {errors.studentStream && (
                    <FormErrorMessage>
                      {errors.studentStream.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              ) : null}
            </VStack>
            <PagedFlowLayoutNavSection>
              <Button
                variant="ms-outline"
                onClick={prevPage}
                leftIcon={<ArrowBackIcon pt="1px" />}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="ms-solid"
                rightIcon={<ArrowForwardIcon pt="1px" />}
              >
                Continue
              </Button>
            </PagedFlowLayoutNavSection>
          </form>
        </PagedFlowLayoutFormSection>
      </SlideFade>
    </PagedFlowLayout>
  );
}

export default StudentAdditionalInfo;
