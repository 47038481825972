import moment from "moment-timezone";
moment.tz.setDefault("Asia/Singapore");

export function convertToSalesforceDate(date: Date) {
  return moment(date).format("YYYY-MM-DD");
}

export function convertToReadableDate(date: string) {
  return moment(date).format("DD MMM YYYY");
}
