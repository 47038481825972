import _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import PagedFlow from "./student-details/components/PagedFlow";
import ParentGuardianInfo from "./student-details/components/1-ParentGuardianInfo";
import StudentInfo from "./student-details/components/2-StudentInfo";
import StudentAdditionalInfo from "./student-details/components/3-StudentAdditionalInfo";
import CompletionInfo from "./student-details/components/4-CompletionInfo";

import { convertToSalesforceDate } from "./student-details/utils/date";

import snakecaseKeys from "snakecase-keys";
import { ApiService } from "./student-details/api";
import EnrolmentLayout from "./lib/components/EnrolmentLayout";
import { isOldRegistrationSite } from "./App";

// These are the frontend specific types, need to do some transformations before calling the backend
// eg. studentDob and howDidYouHearAboutUs
export type RegistrationFormProps = {
  studentFullName?: string;
  studentDob?: Date;
  studentEmail?: string;
  studentMobileNumber?: string;
  studentSchool?: string;
  studentGender?: string;
  studentNationality?: string;
  studentEthnicity?: string;
  studentAddressBlock?: string;
  studentAddressStreet?: string;
  studentAddressUnitNumber?: string;
  studentAddressPostalCode?: string;
  studentAddressOverseas?: string;
  primaryContactRelationship?: string;
  primaryContactFullName?: string;
  primaryContactMobileNumber?: string;
  primaryContactEmail?: string;
  primaryContactOccupation?: string;
  secondaryContactRelationship?: string;
  secondaryContactFullName?: string;
  secondaryContactMobileNumber?: string;
  secondaryContactEmail?: string;
  secondaryContactOccupation?: string;
  howDidYouHearAboutUs?: string[];
  howDidYouHearAboutUsOthers?: string;
  registrationCentre?: string;
  worksheetArrangement?: string;
  worksheetArrangementCentre?: string;
  academicYearRegisteringFor?: string;
  studentLevel?: string;
  studentStream?: string;
  hasAcknowledged?: boolean;
};

const TOTAL_PAGES = 4;

const RegistrationForm = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(0);
  const [registrationData, setRegistrationData] =
    useState<RegistrationFormProps>({});

  // Define functions for page navigation
  const maxPage = TOTAL_PAGES - 1;
  const nextPage = () => {
    setPage(_.min([page + 1, maxPage]) || 0);
  };
  const prevPage = () => {
    setPage(_.max([page - 1, 0]) || 0);
  };

  // Define functions for managing registration data.
  // Validation will be handled by the individual pages and this update function
  // is only called when user input passes the page's validation.
  const updateRegistration = (pageData: any) => {
    console.log("updating form with page data", pageData);
    setRegistrationData({ ...registrationData, ...pageData });
  };

  const submitRegistration = async (data: RegistrationFormProps) => {
    let modifiedRegistrationData = {
      // Set empty fields (null, "", or undefined) to undefined
      ...Object.fromEntries(
        Object.entries(data).map(([key, value]) => [key, value || undefined])
      ),
      studentDob: convertToSalesforceDate(data.studentDob || new Date()),
      howDidYouHearAboutUs: _.join(data.howDidYouHearAboutUs, ", "),
    };

    try {
      const endpoint = slug
        ? `/enrolments/${slug}/registration`
        : "/registrations";
      await ApiService.post(endpoint, snakecaseKeys(modifiedRegistrationData));
    } catch (err) {
      console.error("There was an error submitting the form", err);
      throw err;
    }

    console.log("submitting registration with data", modifiedRegistrationData);
  };

  const enrolmentLayoutProps = isOldRegistrationSite
    ? {
        title: "Registration Form",
        description:
          "Welcome to Mind Stretcher! To get started, please take a moment to complete the registration form. This form is designed to be quick and straightforward, asking for key details such as your child's information, your contact details, and preferred class schedules. By filling it out, you'll help us ensure a smooth and personalized experience for your child. Thank you for choosing us to support your child's educational journey!",
      }
    : { currentStep: 4 };

  return (
    <EnrolmentLayout {...enrolmentLayoutProps}>
      <PagedFlow page={page}>
        <ParentGuardianInfo
          currPage={page}
          nextPage={nextPage}
          pageInFlow={0}
          registrationData={registrationData}
          updateRegistration={updateRegistration}
        />
        <StudentInfo
          currPage={page}
          nextPage={nextPage}
          prevPage={prevPage}
          pageInFlow={1}
          registrationData={registrationData}
          updateRegistration={updateRegistration}
        />
        <StudentAdditionalInfo
          currPage={page}
          nextPage={nextPage}
          prevPage={prevPage}
          pageInFlow={2}
          registrationData={registrationData}
          updateRegistration={updateRegistration}
        />
        <CompletionInfo
          currPage={page}
          nextPage={nextPage}
          prevPage={prevPage}
          pageInFlow={3}
          registrationData={registrationData}
          updateRegistration={updateRegistration}
          submitRegistration={submitRegistration}
        />
      </PagedFlow>
    </EnrolmentLayout>
  );
};

export default RegistrationForm;
